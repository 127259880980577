import React from 'react';

import { Box } from '~components/atoms/Box';
import { Flag } from '~components/atoms/Flag';
import { Text } from '~components/atoms/Typography';
import { CountryName } from '~constants/flag';
import { getSportIcon } from '~utils/getSportIcon';

interface TitleProps {
  sport: string | null | undefined;
  sportId: number;
  countryCode: string;
  countryName?: string;
  league: string | null | undefined;
}

export const Title = ({
  sportId,
  league,
  countryCode,
  countryName,
}: TitleProps) => {
  return (
    <Box
      flexRow
      alignCenter
      gap={2}
      css={{
        p: '18px',
        color: '$grayMedium',
        '@xs_sm': {
          p: '$2',
        },
      }}
    >
      {getSportIcon(sportId, {
        width: 20,
        height: 20,
      })}
      <Flag
        countryName={countryName as CountryName}
        code={countryCode}
        size="sm"
      />
      <Text level="sm-3" css={{ textShadow: '$generalTextShadow' }}>
        {league}
      </Text>
    </Box>
  );
};
