import { memo, useState } from 'react';

import { Box } from '~components/atoms/Box';
import { Text } from '~components/atoms/Typography';
import { useTranslation } from '~hooks';
import { ChevronDownIcon } from '~icons';
import { Market, Selection, SportEvent } from '~types/events';

import { SelectionItem } from './SelectionItem';

interface MarketContentProps {
  marketsData: Market[];
  eventId: string;
  eventData: SportEvent;
}

const maxNotCollapsedRows = 5;

const getGroupedMarketSelections = (selections: Selection[]) => {
  const selectionMap: (Selection | null)[][] = [];

  // Insert selections into their appropriate places in the matrix and track the max row/column index
  let maxRowIndex = 0;
  let maxColumnIndex = 0;

  for (const selection of selections) {
    const rowIndex = selection.rowIndex ?? 0;
    const columnIndex = selection.columnIndex ?? 0;

    // Expand the selectionMap as necessary
    if (!selectionMap[rowIndex]) {
      selectionMap[rowIndex] = [];
    }

    selectionMap[rowIndex]![columnIndex] = selection;

    // Update the max indices
    maxRowIndex = Math.max(maxRowIndex, rowIndex);
    maxColumnIndex = Math.max(maxColumnIndex, columnIndex);
  }

  // Fill missing slots with empty objects
  for (let rowIndex = 0; rowIndex <= maxRowIndex; rowIndex++) {
    if (!selectionMap[rowIndex]) {
      selectionMap[rowIndex] = [];
    }

    for (let columnIndex = 0; columnIndex <= maxColumnIndex; columnIndex++) {
      if (!selectionMap[rowIndex]![columnIndex]) {
        selectionMap[rowIndex]![columnIndex] = null;
      }
    }
  }

  return selectionMap;
};

export const MarketContent = memo(
  ({ marketsData, eventId, eventData }: MarketContentProps) => {
    const { localized } = useTranslation();
    const [openedMarkets, setOpenedMarkets] = useState<string[]>([]);

    return (
      <Box
        flexCol
        css={{
          gap: '1px',
          overflow: 'hidden',
          background: '$inplayMarketsAccordionContentBgColor',
          borderBottom: '1px solid $inplayMarketsContentBorderColor',
          borderRadius: '$10',
        }}
      >
        {marketsData.map((market, index) => {
          let maxSelectionsLength = 0;

          const groupedSelections = getGroupedMarketSelections(
            market.selections,
          );

          groupedSelections.forEach((row) => {
            if (row.length > maxSelectionsLength) {
              maxSelectionsLength = row.length;
            }
          });

          const isLongerThanMaxRows =
            groupedSelections.length > maxNotCollapsedRows;

          const isOpened = openedMarkets.includes(market.id);

          const loadMoreButton = (
            <Box
              flexCol
              justifyCenter
              alignCenter
              gap={1}
              css={{
                p: '$2',
                color: '$grayMedium',
                '& > svg': {
                  width: '1rem',
                  height: '1rem',
                  transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)',
                },
                '&:active': {
                  opacity: 0.7,
                },
              }}
              onClick={() => {
                setOpenedMarkets((prev) =>
                  prev.includes(market.id)
                    ? prev.filter((id) => id !== market.id)
                    : [...prev, market.id],
                );
              }}
            >
              <Text level="14-20" color="grayMedium">
                {localized(`selection.${isOpened ? 'less' : 'more'}`)}
              </Text>
              <ChevronDownIcon />
            </Box>
          );

          if (!isOpened) {
            groupedSelections.length = maxNotCollapsedRows;
          }

          return (
            <Box
              key={market.id + '-' + index}
              fullWidth
              css={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                columnGap: '0.0625rem', // 1px
                rowGap: '0.0625rem', // 1px
              }}
            >
              {groupedSelections.map((row, rowIndex) => (
                <Box
                  key={`${market.id}-row-${rowIndex}`}
                  fullWidth
                  css={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${maxSelectionsLength}, 1fr)`,
                  }}
                >
                  {row.map((selection, index) =>
                    selection ? (
                      <SelectionItem
                        withBorderRight={index !== row.length - 1}
                        eventData={eventData}
                        status={selection.status}
                        marketId={market.id}
                        eventId={eventId}
                        key={selection.id}
                        selection={selection}
                      />
                    ) : (
                      <Box key={rowIndex + index}></Box>
                    ),
                  )}
                </Box>
              ))}
              {isLongerThanMaxRows && loadMoreButton}
            </Box>
          );
        })}
      </Box>
    );
  },
);
